.stat-card {
    background-color: white;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        opacity: 0.5;
        font-size: 0.8rem;
        margin-bottom: 0;
    }

    .text {
        h4 {
            font-size: 0.95rem;
            font-weight: bold;
            color: #495057;
            margin-bottom: 4px;
        }
    }

    .stat h3 {
        font-size: 1.6rem;
        font-weight: bold;
        text-align: end;

        .badge {
            font-size: 50%;
        }
    }
}
