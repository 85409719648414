.login-btn {
    color: white;
    background-color: #f38820;
    border-color: #f38820;

    &:hover {
        color: white;
        background-color: #c8680b !important;
        border-color: #c8680b !important;
    }
}

.login-btn-tsa {
    color: white;
    background-color: #079fd0;
    border-color: #079fd0;

    &:hover {
        color: white;
        background-color: #008ab7 !important;
        border-color: #008ab7 !important;
    }
}

.login-logo {
    padding: 1rem 2rem 3rem 2rem;
    width: 90%;
}

.login-box {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    @media screen and (min-width: 1200px) {
        max-width: 490px;
    }

    .error {
        color: var(--red);
    }

    span {
        font-size: 0.8rem;
        color: grey;
    }

    .forgot {
        &:hover {
            color: #444444;
        }
    }
}

.flex-box {
    display: flex;
    justify-content: center;
    height: 90vh;
    flex-direction: column;
    flex-grow: 1;
}

.flex-box-container {
    height: 100vh;
    background-color: #f7f7f7;
}
