.admin-navbar {
    margin-bottom: 60px;
    position: fixed;
    top: 0;
    min-height: 54px;
    width: 100%;
    z-index: 10;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.12);

    &.menu-toggle {
        width: calc(100% - 260px);

        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }
}

.mobile-menu {
    display: none;
}

@media screen and (max-width: 991px) {
    .admin-navbar {
        .mobile-menu {
            display: block;
        }
    }
}

.hamburger-react {
    cursor: pointer;
    div {
        height: 2px !important;
    }
}

.profile-initials {
    color: #fff;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    text-transform: uppercase;
    border-radius: 100%;
    line-height: 2.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
    user-select: none;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.6);
}
