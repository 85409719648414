.blog{
    
    textarea { 
        resize:vertical; 
        max-height:300px; 
        min-height:200px; 
        overflow: auto;
    }

   .preview {
        border: 1px solid #ccc;
        border-radius: 4px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 280px;

        img {
            border-radius: 3.5px;
            text-align: center;
        }

        i {
            font-size: 3.5rem;
            padding: 0.5rem;
            margin: auto;
            justify-content: center;
            align-items: center;
        }
    }

    .badge{
        font-size:1rem;
        font-weight: normal;
        padding: 5px 12px;
        color: #fff;

        .remove{
            cursor: pointer;
            color: #a70303;
        }
    }

}

