.primary-button.btn-primary {
    &:hover {
        color: var(--hover-text-color) !important;
        background-color: var(--hover-color) !important;
        border: 1px solid var(--hover-color) !important;
    }

    &:disabled {
        background-color: rgb(168, 168, 168) !important;
        border: 1px solid rgb(168, 168, 168) !important;
        &:hover {
            background-color: grey !important;
            cursor: not-allowed;
        }
    }
}

.btn {
    &:focus,
    &:active {
        outline: none !important;
        box-shadow: 0 0 0 0.1rem rgba(186, 186, 186, 0.5) !important;
    }
}

.btn-append{
    line-height:1.3rem!important;
}
