.regDetails {
    p {
        font-size: 0.9rem;
        padding: 10px;
        background: #f6f6f6;
        border-radius: 0.4rem;

        span {
            line-height: 23px;
        }
    }
}
