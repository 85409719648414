@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "./Variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");

$theme-colors: (
  primary: #f38820,
  white: #ffff,
  info: #4dc5ff,
);

@import "../node_modules/bootstrap/scss/bootstrap";

body {
  overflow-x: hidden;
}
* {
  font-family: "Poppins", sans-serif;
}
.clickable {
  cursor: pointer;
}

.main-container {
  padding-top: 54px;
  float: right;
  height: 100vh;
  width: 100%;
  background-color: #f4f3ef;
  -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  position: relative !important;
  background: none !important;
  display: flex;
  flex-flow: column;
  left: 0;

  &.menu-toggle {
    @media screen and (max-width: 991px) {
      left: 260px;
    }
    @media screen and (min-width: 991px) {
      width: calc(100% - 260px);
    }
  }
}

.content-container {
  flex-grow: 1;
  background-color: #f7f7f7;

  .content {
    padding-top: 1rem;
  }
}

.wrapper {
  height: 100vh;
  display: flex;
  flex-flow: column;
  position: relative;

  .wrapper-content {
    flex-grow: 1;
    background-color: #f7f7f7;
  }
}

@media (min-width: 992px) {
  .hideLg {
    display: none;
  }
}

h1 {
  font-size: 1.25rem;
}

@media screen and (max-width: 991px) {
  .sidebar-open .contentContainer {
    right: 0;
    -webkit-transform: translate3d(260px, 0, 0);
    transform: translate3d(260px, 0, 0);
  }
}

.loading-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .loading {
    width: 80%;
    @media (min-width: 450px) {
      width: 60%;
    }
    @media (min-width: 992px) {
      width: 40%;
    }
  }
}

/*//////////////////////////////////////////////////////////////////
[ Form ]*/
.form {
  label {
    font-size: 0.88rem;
    margin-left: 1px;
  }
  .form-control:disabled,
  .form-control[readonly] {
    color: #797c7f;
    background-color: #f1f2f4;
  }
}

.form-control,
.form-group {
  font-size: 0.85rem;
  input,
  select {
    &:focus {
      box-shadow: none !important;
    }
  }
}
label {
  font-size: 0.88rem;
}

.custom-file-input {
  cursor: pointer;
}
/*//////////////////////////////////////////////////////////////////
[ Table ]*/

.table {
  background-color: #fff;
  color: #585858;
  span {
    cursor: pointer;
    line-height: 20px;
  }
  .view {
    color: #808080;
    &:hover {
      color: #424242;
    }
  }

  .delete {
    color: var(--red);
    &:hover {
      color: #a70303;
    }
  }

  .no-data {
    background-color: #f0f0f0;
    text-align: center;
  }

  thead {
    background-color: #fff;
    th {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.85rem;
      border-bottom: 2px solid #d4d4d4;
    }
  }

  th,
  td {
    padding: 0.7rem;
    border-top: 1px solid #d4d4d4 !important;
  }

  tbody td {
    font-size: 0.866rem;
  }

  tbody tr {
    &:hover {
      background-color: #f0f0f0;
    }
  }
  tbody tr:nth-child(even) {
    background-color: #fbfbfb;
    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.table-wrapper {
  padding: 20px;
  border: 1px solid #ebebeb;
  margin-bottom: 1.5rem !important;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem !important;
  box-shadow: 1px 1px 6px rgba(147, 147, 147, 0.2);
}

.register-container {
  background-color: #f7f7f7;
  min-height: 100vh;

  @media screen and (min-width: 768px) {
    height: 100vh;
  }
}

.register {
  padding: 3rem 1.5rem !important;
  @media screen and (min-width: 576px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
  }
  @media screen and (min-width: 768px) {
    height: 100%;
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  @media screen and (min-width: 922px) {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
}

.register-img img {
  object-fit: cover;
  height: 100vh;
  background-size: cover;
}

.action-btn {
  font-size: 15px !important;
  padding: 0.32rem 0.75rem !important;
}

.errorMessage {
  color: var(--red);
}

//Scroll bar css
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background: #b4b4b462;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background: #75757542;
}
::-webkit-scrollbar-track {
  background: #aaaaaa42;
  border-radius: 7px;
  //box-shadow: inset 7px 10px 12px #f0f0f0;
}

//progress bars
.progress {
  border-radius: 0.5rem;
  .progress-bar {
    background-color: var(--primary-orange);
  }
}
