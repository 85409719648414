#sidebar {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  height: 100%;
  bottom: 0;
  width: 260px;
  left: 0;
  z-index: 1030;
  -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  // -webkit-transform: translateZ(0);
  // transform: translateZ(0);
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.12);

  display: flex;
  flex-flow: column;
  height: 100%;

  .sidebar-menu {
    position: relative;
    box-shadow: inset 0 20px 20px -20px var(--fade);
    padding-bottom: 50px;
    height: 80% !important;
    overflow-y: auto;
    flex: 1 1 auto;
  }
}

@media screen and (max-width: 991px) {
  #sidebar {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: 260px;
    right: auto;
    left: 0;
    z-index: 1032;
    visibility: visible;
    overflow-y: visible;
    padding: 0;
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -webkit-transform: translate3d(-260px, 0, 0);
    // transform: translate3d(-260px, 0, 0);

    &.sidebar-open {
      transform: translate3d(0, 0, 0);
    }
  }
  // .sidebar-logo {
  //   height: 200px;
  // }
}

.sidebar-open {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.sidebar-hidden {
  transform: translate3d(-260px, 0, 0);
}

.sidebar-logo {
  max-height: 30%;
  //border-bottom: 1px solid rgb(223, 86, 26);

  img {
    max-height: 100%;
  }
}

.sidebar-submenu {
  ol {
    padding-left: 0 !important;
    // background: lightgrey;
    li {
      padding-left: 45px;
    }
  }
}

.sidebarBg {
  background: #202020;
}

#sidebar ul li {
  cursor: pointer;
}

#sidebar.active {
  left: 0px;
}

#sidebar .sidebar-menu ul li a {
  text-decoration: none;
  align-items: center;
  line-height: 40px;
  transition: all 150ms ease;
  display: inline-flex;

  i {
    font-size: 20px;
    margin-left: 10px;
    margin-right: 20px;
  }
}

#sidebar .sidebar-submenu ol li a {
  text-decoration: none;
  display: flex;
  align-items: center;
  line-height: 25px;
  font-size: 14px;
  margin-left: 15px;
}

.sidebar-menu ul li {
  .item {
    font-weight: 500;
    font-size: 0.95rem;
  }
  transition: all 150ms ease;

  &:hover {
    background-color: var(--hover-color) !important;
    color: white;
  }
}

.sidebar-submenu ol li {
  transition: all 250ms ease;
  &:hover {
    background-color: var(--sub-menu-hover-color) !important;
  }
}

.sidebar-submenu ol li {
  &:hover {
    color: var(--hover);
  }
}

#sidebar a {
  color: var(--ol-color);
  text-decoration: none;
}

.close {
  color: #fff !important;
  padding-right: 10px !important;
}

.user {
  width: 6rem;
}

.user-text p {
  color: #efefef;
  text-align: center;
}

.login {
  padding: 15px 40px;
}

.login input {
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #474646;
  color: #8f8f8f;
}

.login input:focus {
  color: #8f8f8f;
  background: #474646;
  text-shadow: 0px 0px 10px rgba(22, 199, 255, 0.5);
}

.login ul li {
  list-style: none;
  padding: 15px 30px;
}

.login p,
.login a {
  color: #8f8f8f;
}

.login a:active {
  text-decoration: none;
}

.sidebar-menu ul li {
  list-style: none;
}

.sidebar-menu ul li a {
  display: block;
  padding: 5px 8px;
}

.sidebar-menu ul {
  margin-bottom: 0;
  padding-left: 0;
}

.s-icon {
  margin-right: 10px;
  font-size: 22px;
  width: 30px;
  height: 30px;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
}

.sidebar-submenu ul li {
  padding-left: 25px;
  font-size: 16px;
}

.chev i {
  font-weight: 900;
  font-style: normal;
  float: right;
  padding: 6px;
  font-size: 13px;
  position: relative;
  top: 14px;
  left: -16px;
}

.notification {
  background-color: red !important;
  color: white !important;
}
