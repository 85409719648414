.feedbackform {
    .question {
        p {
            margin-bottom: 0;
            span {
                font-size: 0.8rem;
                margin-left: 5px;
                color: var(--primary-grey);
                line-height: 23px;
            }

            .fa-chevron-up,
            .fa-chevron-down {
                font-size: 14px;
            }

            .icon {
                &:hover {
                    color: var(--primary-orange);
                }
            }

            .delete {
                &:hover {
                    color: var(--red);
                }
            }

            .fa-times {
                color: var(--red);
            }
        }

        .drag {
            span {
                color: #c5c3c3;
                line-height: 23px;
            }
        }
    }

    .answerWrapper {
        margin: 15px;
        width: 100%;

        .alert {
            font-size: 0.8rem;
        }

        .answer {
            padding: 15px;
            background-color: #f6f6f6;
            margin: 12px 0;
            border-radius: 0.8rem;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12);
        }

        .answerHover {
            padding: 15px;
            background-color: #e7e7e7;
            margin: 12px 0;
            border-radius: 0.8rem;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12);
        }
    }
}
