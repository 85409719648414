.incomplete {
    opacity: 0.15;
    filter: alpha(opacity=40);
    padding: 1rem;
    display: inline-block;
    align-items: center;
    justify-content: space-around;
    width: 6rem;
    cursor: pointer;
}

.complete {
    padding: 1rem;
    display: inline-block;
    align-items: center;
    justify-content: space-around;
    width: 6rem;
    cursor: pointer;
}
