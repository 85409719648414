.like {
    :hover {
        color: #25b329;
    }

    cursor: pointer;
}

.liked {

    color: #25b329;

    cursor: pointer;
}

.comment {
    cursor: pointer;
}

.commentContainer {
    width: 100%;
    margin: 0 auto;
}

// div.commentContainer>div:nth-of-type(odd) {
//     background: #e0e0e0;
// }

.post {
    box-shadow: 0px 0px 20px 0px rgba(157, 155, 155, 0.2);
}

h1 {
    font-size: 1.3rem;
    font-weight: 510;
}