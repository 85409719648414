.theme {
    input[type="file"] {
        cursor: pointer;
        &:focus {
            outline: unset !important;
        }
    }

    .theme-content {
        display: flex;
    }

    .colour-box {
        border-radius: 6px;
        border: 1px solid #ccc;
        width: 100%;
        display: flex;
        overflow: hidden;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.1);

        input {
            border: none;
            outline: 0;
            padding: 0;
        }
    }

    input[type="color"] {
        -webkit-appearance: none;
        cursor: pointer;
    }

    input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0;
    }

    input[type="color"]::-webkit-color-swatch {
        border: none;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    .upload-container {
        label {
            margin-bottom: 0 !important;
        }
    }

    .preview {
        border: 1px solid #ccc;
        border-radius: 4px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
        height: 180px;
        display: flex;
        padding: 0 1rem;

        img {
            width: auto;
            padding: 0.5rem;
            margin: auto;
            max-height: 180px;
        }

        i {
            font-size: 3.5rem;
            padding: 0.5rem;
            margin: auto;
            justify-content: center;
            align-items: center;
        }
    }

    .logo-upload {
        //border: 1px solid #ccc;
        // display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0 !important;
        cursor: pointer;
    }
}

#colours-container {
    background: lightgray;
    padding: 20px;
    padding-top: 40px;
    user-select: none;
}

.colour {
    height: 50px;
    width: calc(33.33% - 10px);
    background: #555;
    display: inline-block;
    cursor: pointer;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.12);
    transition: none;

    &:not(:last-child) {
        margin-right: 10px;
    }
}

#colour-primary {
    background: hsl(252, 80%, 68%);
}
