.insights {
    p {
        font-size: 0.9rem;
        padding: 10px;
        background: #f6f6f6;
        border-radius: 0.4rem;
        margin-bottom: 9px;

        .yes {
            color: green;
        }

        .no {
            color: var(--red);
            margin-right: 10px;
        }
        span {
            float: right;
            font-weight: bold;
            line-height: 23px;
        }
    }
}
