.page-heading {
    display: flex;
    padding: 1rem;
    align-content: center;
    align-items: center;
    background-color: #fdfdfd;
    border-bottom: 1px solid #e6e6e6;
    .heading {
        h1,
        p {
            margin-left: 1rem;
        }

        h1 {
            margin-bottom: 2px;
        }

        p {
            font-size: 14px;
            color: var(--primary-grey);
            margin-bottom: 0;
        }

        @media screen and (max-width: 576px) {
            width: 100%;
            text-align: center;
            p,
            h1 {
                margin: 0;
            }
        }
    }
    .icon i {
        font-size: 1.4rem;
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
        padding: 0.83333rem;
        align-content: center;
        display: flex;
    }
    .icon {
        @media screen and (max-width: 576px) {
            margin: auto;
            margin-bottom: 1rem;
        }
    }
    .close {
        flex-direction: row;
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
    }
}
