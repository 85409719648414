.card {
    // border: none !important;
    border: 1px solid #ebebeb;
    margin-bottom: 1.5rem !important;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0.25rem !important;
    box-shadow: 1px 1px 6px rgba(147, 147, 147, 0.2);

    .card-heading {
        padding: 1.2rem 1.25rem 0.1rem;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.85rem;
    }
}
